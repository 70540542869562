.login_left{
    text-align: center;
    color: #fff;
    padding:30px;
}
.login_left p{
    padding: 10px 10px 0;
}
.login_left .btn{
    border: none;
    background: #f8f8f8;
    background-color: #f8f8f8;
    font-weight: 600;
    color: #555;
    padding: 10px;
    background-image: none;
}
.login_left .btn:hover{
    background-image: none !important;
    background: #F79434;
    background-color: #F79434;
    font-weight: 600;
    color: #fff;
    padding: 10px;
}

.login_right{
    border: none;
    background: #f8f8f8;
    padding: 50px;
}
@media only screen and (min-width: 600px) {
    .login_right{
        border-top-left-radius: 10% 50%;
        border-bottom-left-radius: 10% 50%;
    }
}
.login_right h2{
    text-align: center;
    margin-bottom: 10px;
    color: #555;
}
.login-form{

}
.buttonText{
    color: "#000";
}
.buttonText:hover{
    text-decoration: underline;
    cursor: pointer;
}

.classInput {
    color: #f1f1f1 !important;
    background-color: transparent !important;
    border-color: #2b3553;
}
.classInput2 {
    color: #f1f1f1 !important;
    background-color: transparent !important;
    border-color: #2b3553;
    min-width: 80px;
}

.react-tel-input .country-list .country{
    color: black;
}