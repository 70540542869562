$white: #ffffff;
$text-color: #4d4d4d;

.flag-select {
  display: block;
  width: 100%;
  color: "#000";
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid;
  border-color: #000;
  border-radius:0.2857rem;
  
  &__btn {
    color: $text-color;
    border: none;
    background: transparent;

    outline: none !important;
    outline-color: transparent;

    &:after, &[aria-expanded="true"]:after {
      content: " ";
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &:after {
      border-top: 5px solid $text-color;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0;
    }

    &[aria-expanded="true"]:after {
      border-top: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $text-color;
    }

    &:before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
    }
  }

  &__option {

    &s {
      position: absolute;
      z-index: 999999;
      width: 90%;
      border: .5px solid #000;
      border-radius: 3px;
      background: $white;
      margin-top: 8px;
      padding: 8px 0;
      max-height: 160px;
      overflow: auto;

      &.to--left {
        right: 10px;
      }
    }

    cursor: pointer;
    padding: 0 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap;
    color: #000;

    &--placeholder {
      height: inherit;
      width: inherit;
      display: inline-block;
      vertical-align: middle;
    }

    &:not(&--placeholder) {
      &:hover, &:focus {
        outline: none;
        background: white;
      }
    }

    &.has-label {
      padding: 0 20px 3px 8px;
    }

    &__label {
      font-size: 1em;
      position: relative;
      padding-left: 9px;
    }

    &__icon {
      width: 1.3em;
      height: 1.3em;
      position: relative;
    }
  }

  .filterBox {
    width: 100%;

    input {
      width: 90%;
      margin: 0 4%;

      &:focus {
        outline: none
      }
    }
  }

  .hidden {
    display: none;
  }

  .no--focus {
    pointer-events: none;
  }
}

.flag-select:hover {
  border-color: #F79434;
}
      